export const firebaseConfig = {
  apiKey: 'AIzaSyBw6-InBvhttfFr0v2mui6PFmzUoJ_xJvw',
  authDomain: 'q42puzzels-test.firebaseapp.com',
  databaseURL: 'https://q42puzzels-test.firebaseio.com',
  projectId: 'q42puzzels-test',
  storageBucket: 'q42puzzels-test.appspot.com',
  messagingSenderId: '35656678118',
  appId: '1:35656678118:web:4f92b3a05c3eab366a1927',
  measurementId: 'G-EN45WSMVC4',
};

export const functionsUrl = 'https://europe-west2-q42puzzels-test.cloudfunctions.net/';

export const env: 'LOCAL' | 'TEST' | 'PROD' = 'TEST';
export const db: 'EMULATOR' | 'TEST' | 'PROD' = 'TEST';
